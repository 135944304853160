import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { branch, compose, withProps, withPropsOnChange } from 'recompose';
import { isUndefined } from '@packages/helpers/core/common';
import styles from '../../styles/components/informer.module.scss';
import { INFORMER_TYPES } from '../../helpers/components/informer';
import { InteractiveVisibleDiv } from '../layout/interactive-visible-div';
import { PurifiedHtmlContainer } from '../layout/purified-html-container';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { withModalInformer } from './hocs/withModalInformer';

const InformerWrapperController = ({ children, onClick, className }) => {
  if (onClick) {
    return (
      <InteractiveVisibleDiv className={className} onClick={onClick}>
        {children}
      </InteractiveVisibleDiv>
    );
  }

  return <div className={className}>{children}</div>;
};

export const InformerComponent = ({ icon, informerHtml, children, onClick, type, className }) => (
  <InformerWrapperController
    className={classnames(className, styles.container, { [styles.interactive]: type === INFORMER_TYPES.modal })}
    onClick={onClick}
  >
    {icon && <div className={styles.icon}>{icon}</div>}
    <span className={styles.text}>
      {children ? children : <PurifiedHtmlContainer renderAs='span' html={informerHtml} parse={false} />}
    </span>
  </InformerWrapperController>
);

/**
 * This is a component that displays a message to the user.
 * It cna have two types: static and modal.
 * Static is a non-interactive message that is displayed in the page.
 * Modal is an interactive block message, clicking on it will open a modal with more information.
 */
export const Informer = compose(
  withProps(({ type }) => ({ type: type || INFORMER_TYPES.static })),
  withPropsOnChange(['icon', 'body'], ({ icon, body }) => {
    const iconName = isUndefined(icon) ? 'info' : icon;

    return {
      informerHtml: body,
      icon: icon !== null && <AffiliateIcon name={iconName} />
    };
  }),
  branch(({ type }) => type === INFORMER_TYPES.modal, withModalInformer)
)(InformerComponent);

Informer.displayName = 'Informer';

/**
 * @typedef {Object} InformerData
 * @property {string} icon - icon
 * @property {string} title - title
 * @property {string} body - a html-like string - modal content for modal type and informer content for static type
 * @property {string} buttonLabel - informer label for modal type
 */
/**
 * @typedef {Object} InformerProps
 * @property {InformerData} data
 * @property {string} type
 */
/**
 * @type {InformerProps}
 */
Informer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INFORMER_TYPES))
};
